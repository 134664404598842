




















































@import '~@/styles/import';

.button-square {
  @apply flex items-center justify-center bg-dark rounded-3 w-40 h-40 border-2 border-dark text-white;
  &:disabled {
    @apply bg-grayLight border-grayBorder text-gray;
  }
}
